<template>
  <div class="filler"></div>
    <div class="loginSection">
      <form @submit.prevent="login">
        <div class="inputSection">
          <label for="username">Username:</label>
          <input type="text" v-model="username" required />
        </div>
        <div class="inputSection">
          <label for="password">Password: </label>
          <input type="password" v-model="password" required />
        </div>
        <button class="loginButton" type="submit">Login</button>
      </form>
      <p v-if="error">{{ error }}</p>
    </div>
  </template>
  
  <script>

  import axios from 'axios';
  export default {
    data() {
      return {
        username: '',
        password: '',
        error: ''
      };
    },
    created() {
      if (!this.getAuthenticated()) {
        this.$router.push('/login');
      }
      this.getData();
    },
    methods: {
      async login() {
        try {
          console.log('Pre API call');
        const apiUrl = process.env.VUE_APP_API_BASE_URL + '/nodejsa2/login'
        const response = await axios.post(apiUrl , {
          username: this.username,
          password: this.password
        });
  
        if (response.data.success) {
          this.setAuthenticated(true, 3);
          this.$router.push('/cms');
        } else {
          this.error = 'Invalid username or password';
        }
      } catch (error) {
        this.error = 'An error occurred. Please try again.';
      }
      },
      setAuthenticated(value, expirationInMinutes) {
      const now = new Date();
      const item = {
        value: value,
        expiration: now.getTime() + expirationInMinutes * 60000,
      };
      localStorage.setItem('authenticated', JSON.stringify(item));
    },
    getAuthenticated() {
      const itemStr = localStorage.getItem('authenticated');
      if (!itemStr) {
        return false;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() > item.expiration) {
        localStorage.removeItem('authenticated');
        return false;
      }
      return item.value;
    },
    async getData() {
      const apiUrl = process.env.VUE_APP_API_BASE_URL + '/nodejsa2/';
      await axios.get(apiUrl)
        .then(response => {
          console.log(response.data);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
     }
    }
  };
  </script>

<style scoped>
.filler {
  height: 100px;
}
.loginSection {
  display: block;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  @media (max-width: 880px) {
    margin-top: 25%;
}
}

.loginButton {
  width: 25%;
  font-size: 18px;
  background-color: #595c5e;
  color: white;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  @media (max-width: 880px) {
    width: 80%;
    height: 2.5em;
  }
}

.loginButton:hover {
  background-color: #0056b3;
}

.loginButton:active {
  background-color: #004085;
}
.inputSection {
  margin-bottom: 10px;
}
</style>