<template>
    <div v-if="!getAuthenticated()" class="updateText">
        <updateMarqueeText />
    </div>


</template>

<script>
import updateMarqueeText from '../Admin/UpdateFiles/UpdateMarqueeText.vue';
export default {

    components: {
        updateMarqueeText,
    },
    
    methods: {
      getAuthenticated() {
      const itemStr = localStorage.getItem('authenticated');
      if (!itemStr) {
        return false;
      }
      const item = JSON.parse(itemStr);
      const now = new Date();
      if (now.getTime() > item.expiration) {
        localStorage.removeItem('authenticated');
        return false;
      }
      return item.value;
    },

}
}
</script>
<style scoped>
.updateText {
    margin-top: 5%  ;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
}
</style>./UpdateFiles/UpdateMarqueeText.vue