<template>
    <div class="marquee__content">
      <span style="font-weight: bold;">{{ marqueeText }}</span>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {

    data() {
      return {
        marqueeText: '',
      };
    },
    created() {
      this.fetchMarqueeText();
    },
    methods: {
    async fetchMarqueeText() {
      const apiUrl = process.env.VUE_APP_API_BASE_URL + '/nodejsa2/marquee-text/';
      await axios.get(apiUrl)
        .then(response => {
          this.marqueeText = response.data.message;
        })
        .catch(error => {
          console.error('Error fetching marquee text:', error);
        });
    }
  }
  };
  </script>
  
  <style>
  .marquee {
      background-color: #212121;
      padding: 1rem;
  }
  
  .marquee__content {
      display: inline-block;
      color: white;
      font-size: 1.5rem;
      font-family: 'Roboto', sans-serif;
      animation: pulse 2s infinite;
  }
  
  @keyframes pulse {
      0% { transform: scale(1); opacity: 1; }
      50% { transform: scale(1.1); opacity: 0.5; }
      100% { transform: scale(1); opacity: 1; }
  }
  </style>